import React from 'react';
import Behaviors from '../Behaviors';
import Text from './Text';
import BehaviorDropdown from './BehaviorDropdown';
import FontSizeDropdown from './FontSizeDropdown';
import VariantOption from './VariantOption';
import ButtonHeight from './ButtonHeight';

const Content = () => {
  return (
    <>
      <Text />
      <BehaviorDropdown />
      <Behaviors />
      <ButtonHeight />
      <VariantOption />
    </>
  );
};

export default Content;
