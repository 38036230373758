import produce from 'immer';
import set from 'lodash/set';
import React, { useEffect, useMemo, useState } from 'react';
import useValidator from 'hooks/use-validator';
import useRealtimeDocument from 'hooks/use-realtime-document';
import IObject, { IChannelOfflineContent, IScheduleItem } from 'models/IObject';
import { replace } from 'services/app-router/actions';
import { useSelector } from 'hooks';
import { useNavigation } from 'hooks/use-navigation';
import { useDispatch } from 'react-redux';
import JsonDebug from 'components/dev/JsonDebug';
import TranslatedText from 'components/i18n/TranslatedText';
import hash from 'json-stable-stringify';
import { getPath } from 'services/app-router/selectors/common';
import { getPageSlug } from 'services/app/selectors';
import { pageTypes } from 'services/app/constants';
import { useLocalizedPageOrChannelTitle } from 'hooks/localization/useLocalizedPageOrChannelTitle';
import Toolbar from 'components/admin-bridge/Toolbar/Toolbar';
import DocumentTitle from 'components/application/Site/DocumentTitle';
import { AdminActionEvents } from 'services/admin/models';
import { trackEvent } from 'services/segment-analytics';
import PageEditorToolbar from './PageEditorToolbar';
import OfflineSection from '../PageEditorOfflineSection';
import LiveStreamsSection from '../PageEditorLiveStreamsSection';
import ScheduleSection from '../PageEditorScheduleSection';

import {
  Container,
  ContentHeader,
  Priorities,
  PriorityHelper,
} from './styles';
import useSetPage from 'hooks/use-set-page';

const EMPTY_ARRAY: unknown[] = [];

const paths = {
  LANDING: 'data.landingContent',
  LIVESTREAMS: 'data.content.live.liveStreams',
  OFFLINE: 'data.content.offline',
  SCHEDULE: 'data.content.live.schedule',
};

interface IPageEditorProps {
  canPublish: boolean;
  docExists: boolean;
  homeId: string;
  initialDoc: IObject;
  onPublish: () => void;
  onSave: (doc: IObject) => void;
  onSetHomeId?: ((id: string) => unknown) | null;
}

export default function PageEditor({
  canPublish,
  docExists,
  homeId,
  initialDoc,
  onPublish,
  onSave,
  onSetHomeId,
}: IPageEditorProps) {
  const dispatch = useDispatch();
  const pageSlug = useSelector(getPageSlug);
  const [doc, setDoc] = useState(initialDoc);
  const [currentSlugs, setCurrentSlugs] = useState([doc.slug]);
  const [validationEnabled, setValidationEnabled] = useState(false);
  const nameOrNull = doc.data?.name || doc.seo?.title;
  const name = typeof nameOrNull === 'string' ? nameOrNull : 'Untitled page';
  const [homePage, homePageLoaded] = useRealtimeDocument<IObject>('objects', homeId);
  const pathname = useSelector(getPath);
  const [firstPublish, setFirstPublish] = useState(!docExists);
  const [, pageTitle] = useLocalizedPageOrChannelTitle();
  const { getPathBySlug } = useNavigation();
  const setPage = useSetPage();

  const {
    valid,
  } = useValidator({
    collection: 'object',
    currentSlugs,
    doc: {
      collection: 'pages',
      site_id: doc.siteId,
      slug: doc.slug,
    },
    enabled: validationEnabled,
  });

  useEffect(() => {
    if (pathname === '/' && homePage) {
      setPage(homePage, homePageLoaded);
    }
  }, [pathname]);

  useEffect(
    () => {
      setValidationEnabled(false);
      setDoc(initialDoc);
    },
    [hash(initialDoc)],
  );

  const save = () => {
    if (valid) {
      if (currentSlugs.indexOf(doc.slug) === -1) {
        setCurrentSlugs([...currentSlugs, doc.slug]);
      }

      const path = getPathBySlug(doc.slug);
      if (pathname !== path) {
        dispatch(replace({ path }));
      }

      onSave(doc);
    }
  };

  useEffect(() => {
    if (hash(doc) !== hash(initialDoc)) {
      save();
    }
  }, [hash(doc)]);

  const pageAlreadyCreated = useMemo(() => Boolean(doc.created), [doc]);

  const trackPagePublish = () => {
    dispatch(
      trackEvent({
        event: doc.type === pageTypes.LANDING ?
          AdminActionEvents.PAGE_PUBLISHED : AdminActionEvents.CHANNEL_PUBLISHED,
        properties: {
          channelId: doc._id,
          channelName: name,
          newPage: !pageAlreadyCreated,
        },
      }),
    );
  };

  const onFirstPublish = () => {
    if (firstPublish) {
      setPage(doc, true);
      setFirstPublish(false);
    }
  };

  const updateSlug = () => {
    const newPagePath = getPathBySlug(doc.slug);

    if (pathname === newPagePath) {
      return;
    }

    dispatch(replace({ path: newPagePath }));
  };

  const publish = () => {
    onPublish();
    trackPagePublish();
    onFirstPublish();
    updateSlug();
  };

  const onScheduleContentChange = (newValue: IScheduleItem[]) => {
    setDoc(
      produce((draft) => set(draft, paths.SCHEDULE, newValue)),
    );
  };

  const onLivestreamContentChange = (newValue: { _id: string }[]) => {
    setDoc(
      produce((draft) => set(draft, paths.LIVESTREAMS, newValue)),
    );
  };

  const onOfflineContentChange = (newValue: IChannelOfflineContent) => {
    setDoc(
      produce((draft) => set(draft, paths.OFFLINE, newValue)),
    );
  };

  return (
    <>
      <DocumentTitle title={pageTitle} />
      <Toolbar
        viewProps={{
          toolbarRight: {
            canPublish,
            homeId: doc._id,
            isDocCreated: !!doc,
            onSetHomeId,
            publish,
            // We redirect to currentSlug if the channel exists, else we use the draft slug
            savedDocSlug: doc?.created ? pageSlug : doc?.slug,
          },
        }}
      >
        {PageEditorToolbar}
      </Toolbar>
      <Container>
        <TranslatedText
          component={ContentHeader}
          stringKey="ADMIN_PAGE_SECTION_CONTENT_PRIORITIES"
        />
        <PriorityHelper>
          <TranslatedText stringKey="ADMIN_LABEL_CONTENT_PRIORITIES_DESCRIPTION" />
          <TranslatedText
            component={Priorities}
            stringKey="ADMIN_LABEL_CONTENT_PRIORITIES"
          />
        </PriorityHelper>
        <ScheduleSection
          onChange={onScheduleContentChange}
          pageId={doc._id}
          value={doc.data?.content?.live?.schedule || EMPTY_ARRAY as React.ComponentProps<typeof ScheduleSection>['value']}
        />
        <LiveStreamsSection
          onChange={onLivestreamContentChange}
          value={doc.data?.content?.live?.liveStreams || EMPTY_ARRAY as { _id: string }[]}
        />
        <OfflineSection
          onChange={onOfflineContentChange}
          value={doc.data?.content?.offline}
        />
        <JsonDebug value={doc} />
      </Container>
    </>
  );
}
