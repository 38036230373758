import ColorOptions from 'components/admin2/ui/ColorOptions';
import React from 'react';
import { useSelector } from 'react-redux';
import { getAdminSurface5 } from 'services/themes';
import usePageBlockBackgroundColorOptions from './use-page-block-background-color-options';

interface IBackgroundColorOptionsProps {
  color: string;
  lastCustomColor: string;
  onChange: (color: string) => void;
}

const BackgroundColorOptions: React.FC<IBackgroundColorOptionsProps> = ({ onChange, color, lastCustomColor }) => {
  const adminSurface5 = useSelector(getAdminSurface5);

  const { colors, getColor, selectedColor } = usePageBlockBackgroundColorOptions(color);

  const handleSelectColor = (targetColor: string) => {
    onChange(getColor(targetColor));
  };

  return (
    <ColorOptions
      colorPickerTitleKey="ADMIN_LABEL_BACKGROUND_COLOR"
      colors={Object.values(colors)}
      selectedColor={selectedColor}
      onChange={handleSelectColor}
      enableColorPicker={true}
      enableTransparent={true}
      colorPickerBackgroundColor={adminSurface5}
      colorPickerOptionColor={lastCustomColor}
    />
  );
};

export default BackgroundColorOptions;
