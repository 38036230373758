import React from 'react';
import GateToggle from 'components/admin-bridge/Toolbar/actions/GateToggle';
import AutoSave from 'components/admin-bridge/Toolbar/actions/AutoSave';
import ToolbarButton from 'components/admin-bridge/Toolbar/actions/Button';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveAction } from 'services/admin/actions';
import { getAdminAlertWarning } from 'services/themes';
import { useNavigation } from 'hooks/use-navigation';

interface PageEditorToolbarProps {
  canPublish: boolean;
  isDocCreated?: boolean;
  publish: () => void;
  savedDocSlug: string;
}

function PageEditorToolbar({
  canPublish,
  isDocCreated,
  savedDocSlug,
  publish,
}: PageEditorToolbarProps) {
  const dispatch = useDispatch();
  const adminAlertWarning = useSelector(getAdminAlertWarning);
  const { getPathBySlug } = useNavigation();

  const handleGoToChannel = () => {
    dispatch(setActiveAction(null));
  };

  return (
    <>
      <GateToggle shouldRender={true} />
      <AutoSave shouldRender={true} />
      {isDocCreated && (
        <ToolbarButton
          data-testid="goToChannelButton"
          href={getPathBySlug(savedDocSlug)} // can't use current doc
          // TODO: URL-drive edit mode & remove this stuff
          onClick={handleGoToChannel}
          query={{
            admin: undefined,
          }}
          stringKey="ADMIN_ACTION_ENTER_CHANNEL"
        />
      )}
      <ToolbarButton
        background={adminAlertWarning}
        data-testid="publishChannelButton"
        disabled={!canPublish}
        onClick={publish}
        stringKey="ADMIN_ACTION_PUBLISH"
      />
    </>
  );
}

export default {
  toolbarRight: PageEditorToolbar,
};
