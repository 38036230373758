import React from 'react';
import OpenLinkInputs from './OpenLinkInputs';
import GoToPageInputs from './GoToPageInputs';
import { useCTAEditorModalContext } from '../Context';
import { isGoToPageBehavior, isOpenLinkBehavior } from '../utils';

const Behaviors = () => {
  const { cta } = useCTAEditorModalContext();

  if (isOpenLinkBehavior(cta.behavior)) {
    return <OpenLinkInputs />;
  }

  if (isGoToPageBehavior(cta.behavior)) {
    return <GoToPageInputs />;
  }

  return null;
};

export default Behaviors;
