import React from 'react';
import Dropdown from 'components/admin2/ui/Dropdown';
import { PageType, type IObject } from 'models';
import { useSelector } from 'react-redux';
import { getIsPagesV3Enabled } from 'services/feature-gate/selectors';
import { PAGE_SERVICE_BASE_URL, PAGE_SERVICE_V3_BASE_URL } from 'config';
import { fetchChannelsAndLandings } from 'services/page';
import { getSiteId } from 'services/app';
import { useCTAEditorModalContext } from '../Context';
import { GoToPageBehaviorData } from '../utils';

const GoToPageInputs = () => {
  const [query, setQuery] = React.useState('');
  const [pages, setPages] = React.useState<IObject[]>([]);
  const isPagesV3Enabled = useSelector(getIsPagesV3Enabled);
  const siteId = useSelector(getSiteId);
  const { cta, onChangeBehavior } = useCTAEditorModalContext();
  const pageServiceURL = isPagesV3Enabled ? PAGE_SERVICE_V3_BASE_URL : PAGE_SERVICE_BASE_URL;
  const behavior = cta.behavior as GoToPageBehaviorData;
  React.useEffect(() => {
    const options = {
      projection: {
        _id: 1,
        'data.name': 1,
        slug: 1,
      },
    };
    fetchChannelsAndLandings({ pageServiceURL, siteId, options }).then(setPages);
  }, []);
  const pageOptions = React.useMemo(() => {
    return pages
    .filter(page => page.data.name?.toLowerCase().includes(query.toLowerCase()))
    .map(page => ({
      label: page.data.name!,
      value: page._id!,
      iconName: page.type === PageType.CHANNEL ? 'channelSelectV3Outline' : 'pageOutline',
    }));
  }, [pages, query]);

  const value = React.useMemo(() => {
    return {
      label: pageOptions.find(page => page.value === (behavior.pageId))?.label,
      value: (behavior.pageId),
    };
  }, [pageOptions, behavior.pageId]);


  const handleChange = (option: typeof value) => {
    if (option.value === behavior.pageId) {
      return;
    }

    onChangeBehavior<typeof behavior>({
      ...behavior,
      pageId: option.value,
    });
  };

  return (
    <Dropdown
      onChange={handleChange}
      options={pageOptions}
      value={value}
      labelKey="SELECT_PAGE"
      padding="0"
      isSearchable={true}
      onInputChange={setQuery}
      isCtaBehavior={true}
    />
  );
};

export default GoToPageInputs;
