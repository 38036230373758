import withRenderCondition from 'components/core/withRenderCondition';
import React, {
  ComponentProps,
  MouseEventHandler,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  publishPendingChanges,
  publishPendingGateChanges,
  publishPendingPanelChanges,
} from 'services/admin/actions';
import { getPageId, isLandingPageType, hasBothYtOrTwitchAndUploadedVideosOnPageContent } from 'services/app/selectors';
import {
  hasUnpublishedAdminChanges,
  getLandingPageContent,
} from 'services/admin/selectors';
import Button from './Button';
import { getAdminAccentSecondary } from 'services/themes';
import { getIsEnterprisePlan, getIsLegacyPlan, getIsUnlimitedPlan } from 'services/billing';
import { showModal } from 'services/modals';
import { ModalKinds } from 'services/modals/types';
import { COMPONENTS_WITH_VIDEO } from 'components/admin-bridge/AdminGridController/constants';
import { isChannelGateEnabled } from 'services/gate/selectors/common';
import { hasYoutubeOrTwitchVideoOnPageContent } from 'services/video';
import useHandlers from '../ToolbarView/Default/hooks/use-handlers';

interface PublishButtonProps
  extends Omit<ComponentProps<typeof Button>, 'stringKey'> {
  hasChanges?: boolean;
}

function PublishButton({ hasChanges, onClick, ...props }: PublishButtonProps) {
  const dispatch = useDispatch();
  const hasAdminChanges = useSelector(hasUnpublishedAdminChanges);
  const pageId = useSelector(getPageId);
  const adminAccentSecondary = useSelector(getAdminAccentSecondary);
  const [isPublished, setIsPublished] = useState(false);
  const isEnterprisePlan = useSelector(getIsEnterprisePlan);
  const isUnlimitedPlan = useSelector(getIsUnlimitedPlan);
  const isLegacyPlan = useSelector(getIsLegacyPlan);
  const isLandingPage = useSelector(isLandingPageType);
  const landingContent = useSelector(getLandingPageContent);
  const isGateEnabled = useSelector(isChannelGateEnabled);
  const hasYoutubeOrTwitchContent = useSelector(hasYoutubeOrTwitchVideoOnPageContent);
  const hasBothYtOrTwitchAndUploadedContent = useSelector(hasBothYtOrTwitchAndUploadedVideosOnPageContent);
  const { exitEditor } = useHandlers();

  const pageHasVideo = useMemo(
    () =>
      landingContent.some((content) =>
      // @ts-ignore
        COMPONENTS_WITH_VIDEO.includes(content.kind),
      ),
    [landingContent],
  );

  const hasEditorChanges =
    hasChanges !== undefined
      ? hasChanges
      : hasAdminChanges;

  const publishEditorChanges = useCallback(() => {
    dispatch(publishPendingChanges({ pageId }));
    dispatch(publishPendingGateChanges());
    dispatch(publishPendingPanelChanges());
  }, [pageId]);

  const handlePublish: MouseEventHandler<HTMLButtonElement> = (e) => {
    /**
     * Pricing V2: If the landing page contains a video and the user is not on an enterprise, legacy or unlimited plan, halt publish.
     *             Admin is allowed to publish a video spotlight or playlist with Youtube or Twitch content if the gate is off
     */
    const planHasGateLimitations = !isEnterprisePlan && !isUnlimitedPlan && !isLegacyPlan;
    const landingPageHasVideo = isLandingPage && pageHasVideo && !hasYoutubeOrTwitchContent;
    const isGateOff = !isGateEnabled;

    if (
      planHasGateLimitations &&
      landingPageHasVideo &&
      isGateOff
    ) {
      dispatch(
        showModal({
          kind: ModalKinds.upgradePlan,
          data: {
            planWarningMessage: 'ADMIN_UPGRADE_PLAN_LANDING_PAGE_WITHOUT_GATE_ERROR',
            preSelectedPlan: 'custom',
          },
        }),
      );
      return;
    }
    // check if landing page has both uploaded and yt/twitch video content but plan doesn't support non-gated shows
    if (
      isGateOff &&
      planHasGateLimitations &&
      isLandingPage &&
      hasBothYtOrTwitchAndUploadedContent
    ) {
      dispatch(
        showModal({
          kind: ModalKinds.upgradePlan,
          data: {
            planWarningMessage: 'ADMIN_UPGRADE_PLAN_WHEN_PAGE_HAS_UPLOADED_AND_YT_OR_TWITCH_CONTENT',
            preSelectedPlan: 'custom',
          },
        }),
      );
      return;
    }

    // Display Halt Gate Modal if gate is activated and admin is trying to publish youtube or twitch content
    if (
      isGateEnabled &&
      isLandingPage &&
      hasYoutubeOrTwitchContent
    ) {
      dispatch(showModal({ kind: ModalKinds.haltGate }));
      return;
    }

    (onClick || publishEditorChanges)(e);
    setIsPublished(true);
  };

  useEffect(() => {
    if (isPublished && !hasEditorChanges) {
      exitEditor();
    }
  }, [hasEditorChanges, isPublished]);

  useEffect(() => {
    if (hasEditorChanges) {
      setIsPublished(false);
    }
  }, [hasEditorChanges]);

  return (
    <Button
      {...props}
      background={adminAccentSecondary}
      data-testid="publishButton"
      disabled={!hasEditorChanges}
      onClick={handlePublish}
      stringKey="ADMIN_ACTION_PUBLISH"
    />
  );
}

export default withRenderCondition(PublishButton);
