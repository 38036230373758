import { mobileOnly, desktopOnly, mobileLandscapeOnly } from 'style/mixins';
import styled, { keyframes } from 'styled-components';
import { CHROMA_KEY_COLOR } from 'style/constants';
import AspectRatioVideo from 'components/Video/AspectRatioVideo';
import QuickEditOverlay from 'components/admin-bridge/QuickEditOverlay';

export const StyledAspectRatioVideo = styled(AspectRatioVideo)`
  width: 100%;
`;

export const VideoObjectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  ${mobileLandscapeOnly`height: 100%;`}

  display: flex;
  align-items: center;

  position: relative;
  margin-bottom: 0 !important;
`;

export const VideoContentWrapper = styled.div<{ isChromaKeyMode: boolean }>`
  display: flex;
  width: 100%;
  height: 100%;
  flex-flow: column nowrap;
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  background: ${props => props.isChromaKeyMode ? CHROMA_KEY_COLOR : 'black'};
  ${desktopOnly`
    border-radius: 5px;
  `}
  > * {
    flex-grow: 1;
  }
  iframe {
    display: block;
  }
  ${mobileOnly`
    overflow: visible;
  `};
`;

export const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: none;
  }
`;

export const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: none;
  }
  to {
    opacity: 0;
    transform: translateX(100%);
  }
`;

export const StyledQuickEditOverlay = styled(QuickEditOverlay)<{
  channelSelectorVisible?: boolean;
  isVideoEmbed: boolean;
}>`
  padding-inline: 20px;
  ${({ channelSelectorVisible, isVideoEmbed }) => (
    !channelSelectorVisible && !isVideoEmbed ? 'padding-bottom: 13px;' : 'padding-bottom: 0;'
  )}
  width: 100%;
  height: 100%;
  ${mobileOnly`padding: 0;`}
`;
