import React, { MouseEventHandler } from 'react';
import TranslatedText from 'components/i18n/TranslatedText';
import { ButtonProps } from 'components/admin2/ui/Button';
import { StyledButton, StyledLink } from '../styles';
import withRenderCondition from 'components/core/withRenderCondition';
import withTooltip from 'components/core/withTooltip';

interface ToolbarButtonProps extends ButtonProps {
  href?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  query?: Record<string, string | undefined>;
  stringKey: string;
}

function ToolbarButton({
  href,
  query,
  stringKey,
  ...props
}: ToolbarButtonProps) {
  const Link = query ? StyledLink : React.Fragment;
  const linkProps = query ? {
    disabled: props.disabled,
    href,
    query,
  } : {};

  return (
    <Link {...linkProps}>
      <TranslatedText
        component={StyledButton}
        componentProps={{
          ...props,
        }}
        stringKey={stringKey}
      />
    </Link>
  );
}

export default withRenderCondition(withTooltip(ToolbarButton), { defaultCondition: true });
