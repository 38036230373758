import Dropdown from 'components/admin2/ui/Dropdown';
import React from 'react';
import { useCTAEditorModalContext } from '../Context';
import { CTABehavior, CTA_BEHAVIOR_DEFAULT_DATA, getCtaBehaviorDropdownOptions } from '../utils';
import { useAdminTranslation } from 'hooks/use-translation';

const BehaviorDropdown = () => {
  const { cta, onChangeBehavior } = useCTAEditorModalContext();
  const { t } = useAdminTranslation();

  const handleChangeBehavior = React.useCallback((option: { value: CTABehavior; }) => {
    const newBehavior = option.value;
    if (cta.behavior.type !== newBehavior) {
      onChangeBehavior(CTA_BEHAVIOR_DEFAULT_DATA[newBehavior]);
    }
  }, [cta.behavior.type, onChangeBehavior]);


  const ctaBehaviorDropdownOptions = getCtaBehaviorDropdownOptions(t);
  const behaviorValue = React.useMemo(() => ctaBehaviorDropdownOptions.find((option) => option.value === cta.behavior.type), [cta.behavior.type]);

  return (
    <Dropdown
      onChange={handleChangeBehavior}
      options={ctaBehaviorDropdownOptions}
      value={behaviorValue}
      labelKey="BEHAVIOR"
      padding="0"
      isCtaBehavior={true}
      dropdownMaxHeight="unset"
    />
  );
};

export default BehaviorDropdown;
